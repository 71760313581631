export default class OrderService {
    constructor() {
        this.id = "";
        this.number = 0;
        this.status = 1;
        this.identification = "";
        this.customer = { id: "", content: "" };
        this.supplier = { id: "", content: "" };
        this.typeMaintenance = 1;
        this.description = "";
        this.registeredDate = "";
        this.registeredUser = "";
    }
    update(data) {
        this.id = data.id;
        this.number = data.number;
        this.status = data.status;
        this.identification = data.identification;

        if (data.customer) {
            this.customer = { id: data.customer.id, content: data.customer.person.companyName + " (" + data.customer.person.tradeName + ")" };
        }

        if (data.supplier) {
            this.supplier = { id: data.supplier.id, content: data.supplier.person.companyName + " (" + data.supplier.person.tradeName + ")" };
        }

        this.typeMaintenance = data.typeMaintenance;
        this.description = data.description;
        this.registeredDate = data.registeredDate;
        this.registeredUser = data.registeredUser;
    }
}